// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrdenesPDFComponent-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.OrdenesPDFComponent-modal-content {
  padding: 20px;
  background-color: #ffffff;
  height: 14vw;
  width: 12vw;
  border-radius: 5px;
  position: relative;
}


.OrdenesPDFComponent-modal-close-icon {
    color: #00ddfa;
    height: 30px !important;
    width: 30px !important;
    cursor: pointer;
    position: absolute;
    top: 17px;
    left: 24px;
}

.OrdenesPDFComponent-modal-pdf-icon {
    color: #fa0000;
    height: 90px !important;
    width: 90px !important;
    cursor: pointer;
    position: absolute;
    margin: 6%;
    top: 66px;
    left: 23px;
  }

  .OrdenesPDFComponent-modal-word-icon {
    color: #0043fa;
    height: 90px !important;
    width: 90px !important;
    cursor: pointer;
    position: absolute;
    margin: 6%;
    top: 66px;
    left: 160px;
  }
  


`, "",{"version":3,"sources":["webpack://./src/modules/ordenes/components/ordenesPDF/styles/OrdenesPDFComponent.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,kBAAkB;AACpB;;;AAGA;IACI,cAAc;IACd,uBAAuB;IACvB,sBAAsB;IACtB,eAAe;IACf,kBAAkB;IAClB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,cAAc;IACd,uBAAuB;IACvB,sBAAsB;IACtB,eAAe;IACf,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,UAAU;EACZ;;EAEA;IACE,cAAc;IACd,uBAAuB;IACvB,sBAAsB;IACtB,eAAe;IACf,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,WAAW;EACb","sourcesContent":[".OrdenesPDFComponent-modal-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n}\n\n.OrdenesPDFComponent-modal-content {\n  padding: 20px;\n  background-color: #ffffff;\n  height: 14vw;\n  width: 12vw;\n  border-radius: 5px;\n  position: relative;\n}\n\n\n.OrdenesPDFComponent-modal-close-icon {\n    color: #00ddfa;\n    height: 30px !important;\n    width: 30px !important;\n    cursor: pointer;\n    position: absolute;\n    top: 17px;\n    left: 24px;\n}\n\n.OrdenesPDFComponent-modal-pdf-icon {\n    color: #fa0000;\n    height: 90px !important;\n    width: 90px !important;\n    cursor: pointer;\n    position: absolute;\n    margin: 6%;\n    top: 66px;\n    left: 23px;\n  }\n\n  .OrdenesPDFComponent-modal-word-icon {\n    color: #0043fa;\n    height: 90px !important;\n    width: 90px !important;\n    cursor: pointer;\n    position: absolute;\n    margin: 6%;\n    top: 66px;\n    left: 160px;\n  }\n  \n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
