import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import Checkbox from '@mui/material/Checkbox';
import './styles/RepuestoEquipoCard.css';
var RepuestoEquipoCard = function (_a) {
    var repuestoEquipo = _a.repuestoEquipo, onClick = _a.onClick, selectMode = _a.selectMode, isSelected = _a.isSelected, onSelectToggle = _a.onSelectToggle;
    var liClassName = "RepuestoEquipoCard-box ".concat(isSelected ? 'RepuestoEquipoCard-selected' : '', "\n    }");
    return (_jsxs("div", { className: liClassName, children: [selectMode && (_jsx(Checkbox, { checked: isSelected, onChange: function (e) {
                    e.stopPropagation(); // Evita que el click en el checkbox dispare otros eventos
                    onSelectToggle(); // Activa la función para agregar o quitar la orden de la selección
                }, className: "SolicitudServicioCard-select-checkbox", sx: {
                    position: 'absolute',
                    top: '10px',
                    left: '102%',
                    width: '20px',
                    height: '20px',
                    cursor: 'pointer',
                    zIndex: 5, // Asegura que esté sobre los demás elementos
                    transform: 'scale(1.5)',
                    '&.Mui-checked': {
                        color: '#00ddfa',
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(0, 221, 250, 0.1)',
                        borderRadius: '50%',
                    },
                    '& .MuiSvgIcon-root': {
                        fill: '#00ddfa',
                    },
                    '& input': {
                        zIndex: 3, // Ajuste del z-index del input en el DOM
                    },
                } })), _jsx("div", { className: "RepuestoEquipoCard-overlap", children: _jsx(HandymanOutlinedIcon, { className: "RepuestoEquipoCard-screw" }) }), _jsxs("div", { className: "RepuestoEquipoCard-div", children: [_jsx("div", { className: "RepuestoEquipoCard-repuestoequipo-name", onClick: onClick, children: repuestoEquipo.repuesto_name }), _jsxs("div", { className: "RepuestoEquipoCard-repuestoequipo-id", children: ["ID: ", repuestoEquipo._id] }), _jsx("div", { className: "RepuestoEquipoCard-text-wrapper", children: "Cliente" }), _jsx("div", { className: "RepuestoEquipoCard-repuestoequipo-3", children: repuestoEquipo.id_cliente ? repuestoEquipo.id_cliente.client_name : 'Cliente no especificado/a' }), _jsx("div", { className: "RepuestoEquipoCard-repuestoequipo" }), _jsx("div", { className: "RepuestoEquipoCard-repuestoequipo-6", children: "Precio" }), _jsx("div", { className: "RepuestoEquipoCard-repuestoequipo-7", children: repuestoEquipo.repuesto_precio })] })] }, repuestoEquipo._id));
};
export default RepuestoEquipoCard;
