var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { editUserById } from '../../services/usersService';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import useUserRoleVerifier from '../../hooks/useUserRoleVerifier';
import MuiAlertComponent from '../../../../components/MuiAlertsComponent'; // Importa MuiAlertComponent
var EditUserButton = function (_a) {
    var userId = _a.userId, userData = _a.userData, onSaveSuccess = _a.onSaveSuccess, isEditing = _a.isEditing;
    var _b = useState(userData), editedUserData = _b[0], setEditedUserData = _b[1];
    var _c = useState([]), alerts = _c[0], setAlerts = _c[1]; // Estado para las alertas
    var loggedIn = useSessionStorage('sessionJWTToken');
    var isAdmin = useUserRoleVerifier(['administrador']);
    var handleCancelEdit = function () {
        onSaveSuccess(userData); // Restablecer los datos originales cuando se cancela la edición
    };
    var handleSaveEdit = function () {
        // Mostrar alerta de "Actualizando Usuario..."
        setAlerts(__spreadArray(__spreadArray([], alerts, true), [{ id: Date.now(), message: 'Actualizando Usuario...', severity: 'info' }], false));
        editUserById(loggedIn, userId, editedUserData)
            .then(function (response) {
            if (response.status === 200 && response.data) {
                onSaveSuccess(response.data);
                setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                    { id: Date.now(), message: 'Información del Usuario Actualizada Satisfactoriamente', severity: 'success' }
                ], false); });
                setTimeout(function () {
                    window.location.reload(); // Recargar la página después de 1 segundo
                }, 1000);
            }
        })
            .catch(function (error) {
            console.error("[EDIT USER ERROR]: ".concat(error));
            setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                { id: Date.now(), message: 'Error al actualizar la información del usuario', severity: 'error' }
            ], false); });
        });
    };
    var handleCloseAlert = function (id) {
        setAlerts(alerts.filter(function (alert) { return alert.id !== id; }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(MuiAlertComponent, { alerts: alerts, onClose: handleCloseAlert }), " ", isEditing ? (_jsxs("div", { className: "UserDetailPage-main", children: [_jsxs("div", { className: "UserDetailPage-section is-active", children: [_jsxs("div", { className: "UserDetailPage-content-1", children: [_jsx("div", { className: "UserDetailPage-subtitle", children: "Nombre de usuario" }), _jsx("input", { className: "UserDetailPage-desc", type: "text", value: editedUserData.username, disabled: true, onChange: function (e) {
                                            return setEditedUserData(__assign(__assign({}, editedUserData), { username: e.target.value }));
                                        } }), _jsx("br", {}), _jsx("br", {}), _jsx("div", { className: "UserDetailPage-subtitle", children: "C\u00E9dula" }), _jsx("input", { className: "UserDetailPage-desc", type: "number", value: editedUserData.cedula, onChange: function (e) {
                                            return setEditedUserData(__assign(__assign({}, editedUserData), { cedula: parseInt(e.target.value) || 0 }));
                                        } }), _jsx("br", {}), _jsx("br", {}), _jsx("div", { className: "UserDetailPage-subtitle", children: "M\u00E1s Info" }), _jsx("input", { className: "UserDetailPage-desc", type: "text", value: editedUserData.more_info, onChange: function (e) {
                                            return setEditedUserData(__assign(__assign({}, editedUserData), { more_info: e.target.value }));
                                        } })] }), _jsxs("div", { className: "UserDetailPage-content-2", children: [_jsx("div", { className: "UserDetailPage-subtitle", children: "Object id" }), _jsx("p", { className: "UserDetailPage-desc", children: userData._id }), _jsx("br", {}), _jsx("div", { className: "UserDetailPage-subtitle", children: "Tel\u00E9fono" }), _jsx("input", { className: "UserDetailPage-desc", type: "text", value: editedUserData.telefono, onChange: function (e) {
                                            return setEditedUserData(__assign(__assign({}, editedUserData), { telefono: e.target.value }));
                                        } }), _jsx("br", {}), _jsx("br", {}), _jsx("div", { className: "UserDetailPage-subtitle", children: "Email" }), _jsx("input", { className: "UserDetailPage-desc", type: "text", value: editedUserData.email, onChange: function (e) {
                                            return setEditedUserData(__assign(__assign({}, editedUserData), { email: e.target.value }));
                                        } })] }), _jsxs("div", { className: "UserDetailPage-content-3", children: [_jsx("div", { className: "UserDetailPage-subtitle", children: "Tipo" }), _jsx("input", { className: "UserDetailPage-desc", type: "text", value: editedUserData.type, disabled: true, onChange: function (e) {
                                            return setEditedUserData(__assign(__assign({}, editedUserData), { type: e.target.value }));
                                        } }), _jsx("br", {}), _jsx("div", { className: "UserDetailPage-subtitle", children: "T\u00EDtulo" }), _jsx("input", { className: "UserDetailPage-desc", type: "text", value: editedUserData.titulo, disabled: true, onChange: function (e) {
                                            return setEditedUserData(__assign(__assign({}, editedUserData), { titulo: e.target.value }));
                                        } }), _jsx("br", {}), _jsx("br", {}), _jsx("div", { className: "UserDetailPage-subtitle", children: "Registro INVIMA" }), _jsx("input", { className: "UserDetailPage-desc", type: "text", value: editedUserData.reg_invima, disabled: true, onChange: function (e) {
                                            return setEditedUserData(__assign(__assign({}, editedUserData), { reg_invima: e.target.value }));
                                        } })] }), _jsx("br", {}), _jsx("br", {})] }), _jsxs("div", { className: "UserDetailPage-buttons-edit", children: [_jsx("button", { className: "UserDetailPage-edit-button", onClick: handleSaveEdit, children: "Guardar" }), _jsx("button", { className: "UserDetailPage-edit-button", onClick: handleCancelEdit, children: "Cancelar" })] })] })) : null] }));
};
export default EditUserButton;
