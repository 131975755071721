var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { getVisitaById, updateVisita } from '../../services/visitasService';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import './styles/PreventivoVisitaRegistrar.css';
var PreventivoVisitaRegistrar = function (_a) {
    var idVisita = _a.idVisita, onActividadesUpdated = _a.onActividadesUpdated;
    var token = useSessionStorage('sessionJWTToken');
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState([]), camposPreventivos = _c[0], setCamposPreventivos = _c[1];
    var _d = useState([]), camposMantenimiento = _d[0], setCamposMantenimiento = _d[1];
    var _e = useState([]), camposCuantitativos = _e[0], setCamposCuantitativos = _e[1];
    var _f = useState([]), camposOtros = _f[0], setCamposOtros = _f[1];
    useEffect(function () {
        var fetchDatos = function () { return __awaiter(void 0, void 0, void 0, function () {
            var visita, cualitativo, camposCualitativos;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsLoading(true);
                        return [4 /*yield*/, getVisitaById(token, idVisita)];
                    case 1:
                        visita = _a.sent();
                        if (visita && visita.id_orden && visita.id_orden[0] && visita.id_orden[0].id_solicitud_servicio && visita.id_orden[0].id_solicitud_servicio.id_equipo && visita.id_orden[0].id_solicitud_servicio.id_equipo.modelo_equipos && visita.id_orden[0].id_solicitud_servicio.id_equipo.modelo_equipos.id_preventivo) {
                            cualitativo = visita.id_orden[0].id_solicitud_servicio.id_equipo.modelo_equipos.id_preventivo.cualitativo;
                            camposCualitativos = cualitativo && Array.isArray(cualitativo) ? cualitativo.map(function (campo) { return ({
                                id_campo: campo._id, // Aquí usamos _id como id_campo
                                title: campo.title,
                                tipo: 'Cualitativo', // Aquí extraemos el tipo desde id_tipo.tipo
                                resultado: '',
                            }); }) : [];
                            setCamposPreventivos(camposCualitativos);
                        }
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        }); };
        fetchDatos();
    }, [token, idVisita]);
    useEffect(function () {
        var fetchDatos = function () { return __awaiter(void 0, void 0, void 0, function () {
            var visita, mantenimiento, camposMantenimiento_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsLoading(true);
                        return [4 /*yield*/, getVisitaById(token, idVisita)];
                    case 1:
                        visita = _a.sent();
                        if (visita && visita.id_orden && visita.id_orden[0] && visita.id_orden[0].id_solicitud_servicio && visita.id_orden[0].id_solicitud_servicio.id_equipo && visita.id_orden[0].id_solicitud_servicio.id_equipo.modelo_equipos && visita.id_orden[0].id_solicitud_servicio.id_equipo.modelo_equipos.id_preventivo) {
                            mantenimiento = visita.id_orden[0].id_solicitud_servicio.id_equipo.modelo_equipos.id_preventivo.mantenimiento;
                            camposMantenimiento_1 = mantenimiento && Array.isArray(mantenimiento) ? mantenimiento.map(function (campo) { return ({
                                id_campo: campo._id, // Aquí usamos _id como id_campo
                                title: campo.title,
                                tipo: 'Mantenimiento', // Aquí extraemos el tipo desde id_tipo.tipo
                                resultado: '',
                            }); }) : [];
                            setCamposMantenimiento(camposMantenimiento_1);
                        }
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        }); };
        fetchDatos();
    }, [token, idVisita]);
    useEffect(function () {
        var fetchDatosCuantitativos = function () { return __awaiter(void 0, void 0, void 0, function () {
            var visita, cuantitativo, camposCuantitativos_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsLoading(true);
                        return [4 /*yield*/, getVisitaById(token, idVisita)];
                    case 1:
                        visita = _a.sent();
                        if (visita && visita.id_orden[0] && visita.id_orden[0].id_solicitud_servicio && visita.id_orden[0].id_solicitud_servicio.id_equipo && visita.id_orden[0].id_solicitud_servicio.id_equipo.modelo_equipos && visita.id_orden[0].id_solicitud_servicio.id_equipo.modelo_equipos.id_preventivo) {
                            cuantitativo = visita.id_orden[0].id_solicitud_servicio.id_equipo.modelo_equipos.id_preventivo.cuantitativo;
                            camposCuantitativos_1 = cuantitativo && Array.isArray(cuantitativo) ? cuantitativo.map(function (item) { return ({
                                id_campo: item.campo._id, // Usando _id del objeto campo para id_campo
                                title: item.campo.title,
                                tipo: 'Cuantitativo', // Extrayendo el tipo de id_tipo
                                resultado: '', // Esto se ajustará más adelante según sea necesario
                                minimo: item.minimo,
                                maximo: item.maximo,
                                unidad: item.unidad,
                                medida: 0,
                            }); }) : [];
                            setCamposCuantitativos(camposCuantitativos_1);
                        }
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        }); };
        fetchDatosCuantitativos();
    }, [token, idVisita]);
    useEffect(function () {
        var fetchDatosOtros = function () { return __awaiter(void 0, void 0, void 0, function () {
            var visita, otros, camposOtros_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsLoading(true);
                        return [4 /*yield*/, getVisitaById(token, idVisita)];
                    case 1:
                        visita = _a.sent();
                        if (visita && visita.id_orden[0] && visita.id_orden[0].id_solicitud_servicio && visita.id_orden[0].id_solicitud_servicio.id_equipo && visita.id_orden[0].id_solicitud_servicio.id_equipo.modelo_equipos && visita.id_orden[0].id_solicitud_servicio.id_equipo.modelo_equipos.id_preventivo) {
                            otros = visita.id_orden[0].id_solicitud_servicio.id_equipo.modelo_equipos.id_preventivo.otros;
                            camposOtros_1 = otros && Array.isArray(otros) ? otros.map(function (campo) { return ({
                                id_campo: campo._id,
                                title: campo.title,
                                tipo: 'Otros',
                                resultado: '',
                            }); }) : [];
                            setCamposOtros(camposOtros_1);
                        }
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        }); };
        fetchDatosOtros();
    }, [token, idVisita]);
    var handleMedicionChange = function (index, medida) {
        var medidaNumerica = parseFloat(medida); // Convertir la entrada a número
        if (!isNaN(medidaNumerica)) {
            var updatedCampos = __spreadArray([], camposCuantitativos, true);
            var campo = updatedCampos[index];
            // Asumimos valores predeterminados para minimo y maximo en caso de que no estén definidos
            var minimo = campo.minimo || 0;
            var maximo = campo.maximo || 0;
            // Actualizar la medida
            campo.medida = medidaNumerica;
            // Validar si la medida está en el rango esperado
            campo.resultado = (medidaNumerica >= minimo && medidaNumerica <= maximo) ? 'paso' : 'fallo';
            setCamposCuantitativos(updatedCampos);
        }
    };
    var handleResultadoChange = function (index, resultado, tipo, medidaInput) {
        var updatedCampos = [];
        switch (tipo) {
            case 'cualitativo':
                updatedCampos = __spreadArray([], camposPreventivos, true);
                break;
            case 'mantenimiento':
                updatedCampos = __spreadArray([], camposMantenimiento, true);
                break;
            case 'cuantitativo':
                updatedCampos = __spreadArray([], camposCuantitativos, true);
                // Convertir la medidaInput a número y realizar la validación
                var medidaNumerica = medidaInput ? parseFloat(medidaInput) : 0;
                var _a = updatedCampos[index], _b = _a.minimo, minimo = _b === void 0 ? 0 : _b, _c = _a.maximo, maximo = _c === void 0 ? 0 : _c; // Usamos valores predeterminados para evitar NaN
                updatedCampos[index].resultado = (medidaNumerica >= minimo && medidaNumerica <= maximo) ? 'paso' : 'fallo';
                break;
            case 'otros':
                updatedCampos = __spreadArray([], camposOtros, true);
                break;
        }
        updatedCampos[index].resultado = resultado;
        switch (tipo) {
            case 'cualitativo':
                setCamposPreventivos(updatedCampos);
                break;
            case 'mantenimiento':
                setCamposMantenimiento(updatedCampos);
                break;
            case 'cuantitativo':
                setCamposCuantitativos(updatedCampos);
                break;
            case 'otros':
                setCamposOtros(updatedCampos);
                break;
        }
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var todosLosCampos, algunCampoIncompleto, now, formattedDateCreated, visitaActual, actividadPreventiva, datosActualizados, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    todosLosCampos = __spreadArray(__spreadArray(__spreadArray(__spreadArray([], camposPreventivos, true), camposMantenimiento, true), camposCuantitativos, true), camposOtros, true);
                    algunCampoIncompleto = todosLosCampos.some(function (campo) {
                        // Verificar que cada campo tenga un resultado definido
                        if (!campo.resultado)
                            return true;
                        return false;
                    });
                    // Si algún campo está incompleto, mostrar una alerta y detener el proceso
                    if (algunCampoIncompleto) {
                        window.alert('Primero debe completar todos los campos.');
                        return [2 /*return*/];
                    }
                    // Proceder con la lógica de envío del formulario...
                    setIsLoading(true);
                    now = new Date();
                    formattedDateCreated = "".concat(now.getFullYear(), "-").concat((now.getMonth() + 1).toString().padStart(2, '0'), "-").concat(now.getDate().toString().padStart(2, '0'), " ").concat(now.getHours().toString().padStart(2, '0'), ":").concat(now.getMinutes().toString().padStart(2, '0'), ":").concat(now.getSeconds().toString().padStart(2, '0'));
                    return [4 /*yield*/, getVisitaById(token, idVisita)];
                case 1:
                    visitaActual = _a.sent();
                    actividadPreventiva = {
                        id_protocolo: '65a93dc689a02ef211e75ec9',
                        date_created: formattedDateCreated,
                        ids_campos_preventivo: todosLosCampos,
                    };
                    datosActualizados = __assign(__assign({}, visitaActual), { actividades: __spreadArray(__spreadArray([], visitaActual.actividades, true), [actividadPreventiva], false) });
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, 5, 6]);
                    return [4 /*yield*/, updateVisita(token, idVisita, datosActualizados)];
                case 3:
                    _a.sent();
                    alert('Actividad de mantenimiento preventivo registrada con éxito.');
                    onActividadesUpdated();
                    return [3 /*break*/, 6];
                case 4:
                    error_1 = _a.sent();
                    console.error('Error al registrar la actividad preventiva:', error_1);
                    alert('Error al registrar la actividad preventiva.');
                    return [3 /*break*/, 6];
                case 5:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var getButtonClass = function (campo, resultado) {
        // Asegúrate de que 'n/a' se convierte a 'na' para que coincida con las clases CSS
        var resultadoClassName = resultado.replace('/', '');
        var isActive = campo.resultado === resultado ? 'active' : '';
        return "PreventivoVisitaRegistrar-".concat(resultadoClassName, "-b ").concat(isActive);
    };
    var getButtonClassCuantitativo = function (campo, resultadoEsperado) {
        // Determinar si este botón debe estar activo basado en el resultado actual del campo
        var isActive = campo.resultado === resultadoEsperado ? 'active' : '';
        // Asegúrate de que 'n/a' se convierte a 'na' para que coincida con las clases CSS
        var resultadoClassName = resultadoEsperado.replace('/', '');
        return "PreventivoVisitaRegistrar-cuantitativo-".concat(resultadoClassName, "-b ").concat(isActive);
    };
    return (_jsx("div", { className: "PreventivoVisitaRegistrar-container", children: _jsx("form", { onSubmit: handleSubmit, className: "PreventivoVisitaRegistrar-form", children: _jsx("div", { className: "PreventivoVisitaRegistrar-actividad-preventivo", children: _jsx("div", { className: "PreventivoVisitaRegistrar-overlap-wrapper", children: _jsxs("div", { className: "PreventivoVisitaRegistrar-overlap", children: [_jsx("div", { className: "PreventivoVisitaRegistrar-title-text", children: "PREVENTIVO" }), _jsx("div", { className: "PreventivoVisitaRegistrar-cualitativo-section", children: _jsxs("div", { className: 'PreventivoVisitaRegistrar-overlap-group', children: [_jsx("div", { className: "PreventivoVisitaRegistrar-cualitativo-titlet-wrapper", children: _jsx("div", { className: "PreventivoVisitaRegistrar-text-wrapper", children: "PRUEBAS CUALITATIVAS" }) }), _jsxs("div", { className: 'PreventivoVisitaRegistrar-overflow-container', children: [_jsx("div", { className: 'PreventivoVisitaRegistrar-container-list', children: camposPreventivos.map(function (campo, index) { return (_jsx("ul", { children: _jsxs("li", { className: "PreventivoVisitaRegistrar-div", children: [_jsx("label", { className: "PreventivoVisitaRegistrar-p", children: campo.title }), _jsx("button", { type: "button", className: getButtonClass(campo, 'paso'), onClick: function (e) {
                                                                        e.preventDefault();
                                                                        handleResultadoChange(index, 'paso', 'cualitativo');
                                                                    }, children: "Pas\u00F3" }), _jsx("button", { type: "button", className: getButtonClass(campo, 'fallo'), onClick: function (e) {
                                                                        e.preventDefault();
                                                                        handleResultadoChange(index, 'fallo', 'cualitativo');
                                                                    }, children: "Fall\u00F3" }), _jsx("button", { type: "button", className: getButtonClass(campo, 'n/a'), onClick: function (e) {
                                                                        e.preventDefault();
                                                                        handleResultadoChange(index, 'n/a', 'cualitativo');
                                                                    }, children: "N/A" })] }) }, index)); }) }), _jsx("div", { className: "PreventivoVisitaRegistrar-separator" })] })] }) }), _jsx("div", { className: "PreventivoVisitaRegistrar-mantenimiento-section", children: _jsxs("div", { className: 'PreventivoVisitaRegistrar-overlap-group', children: [_jsx("div", { className: "PreventivoVisitaRegistrar-cualitativo-titlet-wrapper", children: _jsx("div", { className: "PreventivoVisitaRegistrar-text-wrapper", children: "PRUEBAS MANTENIMIENTO" }) }), _jsxs("div", { className: 'PreventivoVisitaRegistrar-overflow-container', children: [_jsx("div", { className: 'PreventivoVisitaRegistrar-container-list', children: camposMantenimiento.map(function (campo, index) { return (_jsx("ul", { children: _jsxs("li", { className: "PreventivoVisitaRegistrar-div", children: [_jsx("label", { className: "PreventivoVisitaRegistrar-p", children: campo.title }), _jsx("button", { type: "button", className: getButtonClass(campo, 'paso'), onClick: function (e) {
                                                                        e.preventDefault();
                                                                        handleResultadoChange(index, 'paso', 'mantenimiento');
                                                                    }, children: "Pas\u00F3" }), _jsx("button", { type: "button", className: getButtonClass(campo, 'fallo'), onClick: function (e) {
                                                                        e.preventDefault();
                                                                        handleResultadoChange(index, 'fallo', 'mantenimiento');
                                                                    }, children: "Fall\u00F3" }), _jsx("button", { type: "button", className: getButtonClass(campo, 'n/a'), onClick: function (e) {
                                                                        e.preventDefault();
                                                                        handleResultadoChange(index, 'n/a', 'mantenimiento');
                                                                    }, children: "N/A" })] }) }, index)); }) }), _jsx("div", { className: "PreventivoVisitaRegistrar-separator" })] })] }) }), _jsx("div", { className: "PreventivoVisitaRegistrar-cuantitativo-section", children: _jsxs("div", { className: 'PreventivoVisitaRegistrar-overlap-group', children: [_jsx("div", { className: "PreventivoVisitaRegistrar-cualitativo-titlet-wrapper", children: _jsx("div", { className: "PreventivoVisitaRegistrar-text-wrapper", children: "PRUEBAS CUANTITATIVAS" }) }), _jsxs("div", { className: 'PreventivoVisitaRegistrar-overflow-container', children: [_jsx("div", { className: 'PreventivoVisitaRegistrar-container-list-cuantitativo', children: camposCuantitativos.map(function (campo, index) { return (_jsx("ul", { children: _jsxs("li", { className: "PreventivoVisitaRegistrar-div-cuantitativo", children: [_jsx("label", { className: "PreventivoVisitaRegistrar-p", children: campo.title }), _jsx("div", { className: 'PreventivoVisitaRegistrar-cuantitativo-medicion', children: "En ".concat(campo.unidad, " rango ( ").concat(campo.minimo, " a ").concat(campo.maximo, " )") }), _jsx("input", { className: 'PreventivoVisitaRegistrar-cuantitativo-medicion-input', type: 'number', placeholder: 'Ingrese el valor medido...', onChange: function (e) { return handleMedicionChange(index, e.target.value); } }), _jsx("button", { type: "button", className: getButtonClassCuantitativo(campo, 'paso'), onClick: function (e) {
                                                                        e.preventDefault();
                                                                        handleResultadoChange(index, 'paso', 'cuantitativo');
                                                                    }, children: "Pas\u00F3" }), _jsx("button", { type: "button", className: getButtonClassCuantitativo(campo, 'fallo'), onClick: function (e) {
                                                                        e.preventDefault();
                                                                        handleResultadoChange(index, 'fallo', 'cuantitativo');
                                                                    }, children: "Fall\u00F3" })] }) }, index)); }) }), _jsx("div", { className: "PreventivoVisitaRegistrar-separator" })] })] }) }), _jsx("div", { className: "PreventivoVisitaRegistrar-otros-section", children: _jsxs("div", { className: 'PreventivoVisitaRegistrar-overlap-group', children: [_jsx("div", { className: "PreventivoVisitaRegistrar-cualitativo-titlet-wrapper", children: _jsx("div", { className: "PreventivoVisitaRegistrar-text-wrapper", children: "OTRAS PRUEBAS" }) }), _jsxs("div", { className: 'PreventivoVisitaRegistrar-overflow-container', children: [_jsx("div", { className: 'PreventivoVisitaRegistrar-container-list', children: camposOtros.map(function (campo, index) { return (_jsx("ul", { children: _jsxs("li", { className: "PreventivoVisitaRegistrar-div", children: [_jsx("label", { className: "PreventivoVisitaRegistrar-p", children: campo.title }), _jsx("button", { type: "button", className: getButtonClass(campo, 'paso'), onClick: function (e) {
                                                                        e.preventDefault();
                                                                        handleResultadoChange(index, 'paso', 'otros');
                                                                    }, children: "Pas\u00F3" }), _jsx("button", { type: "button", className: getButtonClass(campo, 'fallo'), onClick: function (e) {
                                                                        e.preventDefault();
                                                                        handleResultadoChange(index, 'fallo', 'otros');
                                                                    }, children: "Fall\u00F3" }), _jsx("button", { type: "button", className: getButtonClass(campo, 'n/a'), onClick: function (e) {
                                                                        e.preventDefault();
                                                                        handleResultadoChange(index, 'n/a', 'otros');
                                                                    }, children: "N/A" })] }) }, index)); }) }), _jsx("div", { className: "PreventivoVisitaRegistrar-separator" })] })] }) }), _jsx("button", { type: 'submit', className: "PreventivoVisitaRegistrar-create-t-wrapper", children: "CREAR ACTIVIDAD" })] }) }) }) }) }));
};
export default PreventivoVisitaRegistrar;
