var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { getVisitaById, updateVisita } from '../../services/visitasService'; // Asegúrate de que las rutas sean correctas
import { useSessionStorage } from '../../hooks/useSessionStorage';
import './styles/CambiarRepuestoRegistrar.css'; // Asegúrate de que la ruta de los estilos sea correcta
import { getAllRepuestosEquipos } from '../../../equipos/services/repuestosEquiposService';
var CambiarRepuestoRegistrar = function (_a) {
    var idVisita = _a.idVisita, onActividadesUpdated = _a.onActividadesUpdated;
    var token = useSessionStorage('sessionJWTToken');
    var _b = useState(''), observacion = _b[0], setObservacion = _b[1];
    var _c = useState(''), idRepuesto = _c[0], setIdRepuesto = _c[1];
    var _d = useState(0), cantidad = _d[0], setCantidad = _d[1];
    var _e = useState(0), valorUnitario = _e[0], setValorUnitario = _e[1];
    var _f = useState(false), isLoading = _f[0], setIsLoading = _f[1];
    var _g = useState([]), repuestos = _g[0], setRepuestos = _g[1];
    var _h = useState(''), idRepuestoSeleccionado = _h[0], setIdRepuestoSeleccionado = _h[1];
    // visita && visita.id_orden && visita.id_orden.id_solicitud_servicio && visita.id_orden.id_solicitud_servicio.id_equipo && visita.id_orden.id_solicitud_servicio.id_equipo.id_sede && visita.id_orden.id_solicitud_servicio.id_equipo.id_sede.id_client._id;
    useEffect(function () {
        var fetchDatos = function () { return __awaiter(void 0, void 0, void 0, function () {
            var visita, idCliente, result, repuestosFiltrados;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsLoading(true);
                        return [4 /*yield*/, getVisitaById(token, idVisita)];
                    case 1:
                        visita = _a.sent();
                        console.log(visita._id); // Se imprime correctamente el _id de la visita
                        console.log(visita.id_orden); // Ahora debería mostrar el contenido de 'id_orden' correctamente
                        console.log(visita.id_orden.id_solicitud_servicio); // Ahora debería mostrar 'id_solicitud_servicio'
                        idCliente = '';
                        // Verificamos que cada paso de la cadena de acceso exista antes de acceder al siguiente
                        if (visita && visita.id_orden && visita.id_orden && visita.id_orden.id_solicitud_servicio && visita.id_orden.id_solicitud_servicio.id_equipo && visita.id_orden.id_solicitud_servicio.id_equipo.id_sede && visita.id_orden.id_solicitud_servicio.id_equipo.id_sede.id_client && visita.id_orden.id_solicitud_servicio.id_equipo.id_sede.id_client._id) {
                            idCliente = visita.id_orden.id_solicitud_servicio.id_equipo.id_sede.id_client._id;
                        }
                        if (!idCliente) return [3 /*break*/, 3];
                        return [4 /*yield*/, getAllRepuestosEquipos(token)];
                    case 2:
                        result = _a.sent();
                        repuestosFiltrados = result.repuestoEquipos.filter(function (repuesto) { return repuesto.id_cliente._id === idCliente; });
                        setRepuestos(repuestosFiltrados);
                        _a.label = 3;
                    case 3:
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        }); };
        fetchDatos();
    }, [token, idVisita]);
    var handleChangeRepuesto = function (e) {
        var selectedId = e.target.value;
        setIdRepuestoSeleccionado(selectedId);
        // Encuentra el repuesto seleccionado basado en el id
        var selectedRepuesto = repuestos.find(function (repuesto) { return repuesto._id === selectedId; });
        // Si se encuentra el repuesto, actualiza el valorUnitario con el precio del repuesto
        if (selectedRepuesto) {
            setValorUnitario(selectedRepuesto.repuesto_precio);
            setIdRepuesto(selectedId);
        }
        else {
            // Si por alguna razón no se encuentra el repuesto, resetea el valorUnitario
            setValorUnitario(0);
        }
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var total, now, formattedDateCreated, visitaActual, actividadesActualizadas, datosActualizados, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    if (!idRepuesto || cantidad <= 0 || valorUnitario <= 0) {
                        alert('Por favor, complete todos los campos requeridos.');
                        return [2 /*return*/];
                    }
                    total = cantidad * valorUnitario;
                    now = new Date();
                    formattedDateCreated = "".concat(now.getFullYear(), "-").concat((now.getMonth() + 1).toString().padStart(2, '0'), "-").concat(now.getDate().toString().padStart(2, '0'), " ").concat(now.getHours().toString().padStart(2, '0'), ":").concat(now.getMinutes().toString().padStart(2, '0'), ":").concat(now.getSeconds().toString().padStart(2, '0'));
                    return [4 /*yield*/, getVisitaById(token, idVisita)];
                case 1:
                    visitaActual = _a.sent();
                    actividadesActualizadas = __spreadArray(__spreadArray([], visitaActual.actividades, true), [
                        {
                            id_protocolo: '65a93d9e89a02ef211e75ec5',
                            id_repuesto: idRepuesto,
                            cantidad: cantidad,
                            valor_unitario: valorUnitario,
                            total: total,
                            date_created: formattedDateCreated,
                        }
                    ], false);
                    datosActualizados = __assign(__assign({}, visitaActual), { actividades: actividadesActualizadas });
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 6, 7, 8]);
                    if (!(token && idVisita)) return [3 /*break*/, 4];
                    return [4 /*yield*/, updateVisita(token, idVisita, datosActualizados)];
                case 3:
                    _a.sent();
                    alert('Actividad "Cambiar Repuesto" agregada a la visita con éxito.');
                    onActividadesUpdated();
                    return [3 /*break*/, 5];
                case 4:
                    alert('No se encontró token de sesión.');
                    _a.label = 5;
                case 5: return [3 /*break*/, 8];
                case 6:
                    error_1 = _a.sent();
                    console.error('Error al actualizar la visita:', error_1);
                    alert('Error al actualizar la visita.');
                    return [3 /*break*/, 8];
                case 7:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx("div", { className: "CambiarRepuestoRegistrar-actividad", children: _jsx("form", { onSubmit: handleSubmit, children: _jsxs("div", { className: "CambiarRepuestoRegistrar-cambiar-repuesto", children: [_jsxs("div", { className: "CambiarRepuestoRegistrar-overlap-group", children: [_jsx("div", { className: "CambiarRepuestoRegistrar-cambiar-repuestot", children: "CAMBIAR REPUESTO" }), _jsx("label", { className: "CambiarRepuestoRegistrar-select-repuestot", htmlFor: "idRepuesto", children: "Seleccione el repuesto: *" }), _jsxs("select", { className: "CambiarRepuestoRegistrar-select-repuestoi", id: "idRepuesto", value: idRepuestoSeleccionado, onChange: handleChangeRepuesto, children: [_jsx("option", { value: "", children: "Seleccione un repuesto" }), repuestos.map(function (repuesto) { return (_jsx("option", { value: repuesto._id, children: repuesto.repuesto_name }, repuesto._id)); })] }), _jsx("label", { className: "CambiarRepuestoRegistrar-select-quantityt", htmlFor: "cantidad", children: "Seleccione la cantidad: *" }), _jsx("input", { className: "CambiarRepuestoRegistrar-select-quantity-i", type: "number", id: "cantidad", value: cantidad, onChange: function (e) { return setCantidad(Number(e.target.value)); }, placeholder: "Cantidad" }), _jsx("div", { className: "CambiarRepuestoRegistrar-separator" }), _jsx("label", { className: "CambiarRepuestoRegistrar-valor-unitariot", htmlFor: "valorUnitario", children: "Valor unitario: *" }), _jsx("input", { className: "CambiarRepuestoRegistrar-valor-unitarioi", type: "number", id: "valorUnitario", value: valorUnitario, onChange: function (e) { return setValorUnitario(Number(e.target.value)); }, placeholder: "Valor unitario", disabled: true }), _jsx("label", { className: "CambiarRepuestoRegistrar-total-t", htmlFor: "total", children: "Total: *" }), _jsx("input", { className: "CambiarRepuestoRegistrar-total-input", type: "text" // Cambiado a text para evitar problemas con números negativos
                                , id: "total", value: (cantidad * valorUnitario).toFixed(2), readOnly: true })] }), _jsx("button", { className: "CambiarRepuestoRegistrar-overlap", type: 'submit', children: "CREAR ACTIVIDAD" })] }) }) }));
};
export default CambiarRepuestoRegistrar;
