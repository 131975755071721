var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { getAllSolicitudesServicios } from '../../services/solicitudesServiciosService';
import '../../../../components/filters/styles/AdvancedFilters.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FilterListIcon from '@mui/icons-material/FilterList';
import MuiAlertComponent from '../../../../components/MuiAlertsComponent';
import FiltersCamposToSelectSolicitudesServicios from './FiltersCamposToSelectSolicitudesServicios';
import FiltersInputsSolicitudesServicioComponent from './FiltersInputsSolicitudesServicioComponent';
var AdvancedFiltersSolicitudesServicio = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, onApplyFilters = _a.onApplyFilters, currentPage = _a.currentPage, limitPerPage = _a.limitPerPage, SolicitudPageType = _a.SolicitudPageType;
    var _b = useState(function () {
        var savedFilters = sessionStorage.getItem('advancedFilters');
        return savedFilters ? JSON.parse(savedFilters) : [
            {
                _id: '',
                'id_servicio._id': '',
                'creacion': '',
                'id_equipo.equipo': '',
                'id_solicitud_estado.estado': ''
            }
        ];
    }), filters = _b[0], setFilters = _b[1];
    var _c = useState(filters.map(function () { return false; })), loading = _c[0], setLoading = _c[1];
    var _d = useState(filters.map(function () { return null; })), results = _d[0], setResults = _d[1];
    var _e = useState(false), showViewButton = _e[0], setShowViewButton = _e[1];
    var _f = useState(true), isEditing = _f[0], setIsEditing = _f[1];
    var _g = useState([]), alerts = _g[0], setAlerts = _g[1];
    var _h = useState(false), showFieldSelector = _h[0], setShowFieldSelector = _h[1];
    var _j = useState(function () {
        var savedFields = sessionStorage.getItem('selectedFields');
        return savedFields ? JSON.parse(savedFields) : ['ID', 'SERVICIO', 'SOLICITUD FECHA', 'EQUIPO', 'ESTADO'];
    }), selectedFields = _j[0], setSelectedFields = _j[1];
    var token = useSessionStorage('sessionJWTToken');
    useEffect(function () {
        var savedFilters = sessionStorage.getItem('advancedFilters');
        if (savedFilters) {
            setFilters(JSON.parse(savedFilters));
        }
        var savedSelectedFields = sessionStorage.getItem('selectedFields');
        if (savedSelectedFields) {
            setSelectedFields(JSON.parse(savedSelectedFields));
        }
    }, []);
    var handleAddFilter = function () {
        setFilters(__spreadArray(__spreadArray([], filters, true), [{
                _id: '',
                'id_servicio._id': '',
                'creacion': '',
                'id_equipo.equipo': '',
                'id_solicitud_estado.estado': ''
            }], false));
        setLoading(__spreadArray(__spreadArray([], loading, true), [false], false));
        setResults(__spreadArray(__spreadArray([], results, true), [null], false));
    };
    var handleFilterChange = function (index, field, value) {
        var newFilters = __spreadArray([], filters, true);
        if (selectedFields.includes('ID') && field === '_id') {
            var hexPattern = /^[0-9a-fA-F]*$/;
            if (hexPattern.test(value) && value.length <= 24) {
                newFilters[index][field] = value;
            }
        }
        else if (selectedFields.includes('SERVICIO') && field === 'id_servicio._id') {
            var hexPattern = /^[0-9a-fA-F]*$/;
            if (hexPattern.test(value) && value.length <= 24) {
                newFilters[index][field] = value;
            }
        }
        else if (selectedFields.includes('SOLICITUD FECHA') && field === 'creacion') {
            newFilters[index][field] = value.replace(/[^0-9\-: ]/g, '');
        }
        else if (selectedFields.includes('EQUIPO') && field === 'id_equipo.equipo') {
            newFilters[index][field] = value;
        }
        else if (selectedFields.includes('ESTADO') && field === 'id_solicitud_estado.estado') {
            newFilters[index][field] = value;
        }
        setFilters(newFilters);
        sessionStorage.setItem('userFilters', JSON.stringify(newFilters));
    };
    var handleRemoveFilter = function (index) {
        var newFilters = __spreadArray([], filters, true);
        var newLoading = __spreadArray([], loading, true);
        var newResults = __spreadArray([], results, true);
        newFilters.splice(index, 1);
        newLoading.splice(index, 1);
        newResults.splice(index, 1);
        setFilters(newFilters);
        setLoading(newLoading);
        setResults(newResults);
    };
    var handleSearch = function () { return __awaiter(void 0, void 0, void 0, function () {
        var newAlert, invalidFilters, newAlert, newLoading, newResults, allResults, result, solicitudesServicios, _loop_1, i, paginatedResults, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (filters.length === 0) {
                        newAlert = {
                            id: new Date().getTime(),
                            message: 'Agregue al menos un filtro.',
                            severity: 'info',
                        };
                        setAlerts(__spreadArray(__spreadArray([], alerts, true), [newAlert], false));
                        return [2 /*return*/];
                    }
                    invalidFilters = filters.some(function (filter) {
                        return selectedFields.some(function (field) {
                            if (field === 'ID' && filter._id.length !== 24 && filter._id !== '')
                                return true;
                            if (field === 'SERVICIO' && filter['id_servicio._id'].length !== 24 && filter['id_servicio._id'] !== '')
                                return true;
                            return false;
                        });
                    });
                    if (invalidFilters) {
                        newAlert = {
                            id: new Date().getTime(),
                            message: 'Por favor ingrese un formato de ID válido.',
                            severity: 'error',
                        };
                        setAlerts(__spreadArray(__spreadArray([], alerts, true), [newAlert], false));
                        return [2 /*return*/];
                    }
                    // Validación para forzar el estado a "Pendiente" y servicio a "Preventivo" si SolicitudPageType es "SolicitudesServicios-pendientes-preventivo"
                    if (SolicitudPageType === 'SolicitudesServicios-pendientes-preventivo') {
                        filters.forEach(function (filter) {
                            filter['id_solicitud_estado.estado'] = 'Pendiente';
                            filter['id_servicio._id'] = '65a92d6b956f2f16bab82a9c';
                        });
                    }
                    if (SolicitudPageType === 'SolicitudesServicios-pendientes-CIG') {
                        filters.forEach(function (filter) {
                            filter['id_solicitud_estado.estado'] = 'Pendiente';
                            // Filtrar solicitudes para que no incluyan el servicio con el ID '65a92d6b956f2f16bab82a9c'
                            if (filter['id_servicio._id'] === '65a92d6b956f2f16bab82a9c') {
                                delete filter['id_servicio._id']; // Eliminar el filtro de servicio con ID específico
                            }
                        });
                    }
                    if (SolicitudPageType === 'SolicitudesServicios-aprobadas') {
                        filters.forEach(function (filter) {
                            filter['id_solicitud_estado.estado'] = 'Aprobada';
                        });
                    }
                    if (SolicitudPageType === 'SolicitudesServicios-rechazadas') {
                        filters.forEach(function (filter) {
                            filter['id_solicitud_estado.estado'] = 'Rechazada';
                        });
                    }
                    sessionStorage.setItem('advancedFilters', JSON.stringify(filters));
                    newLoading = filters.map(function () { return true; });
                    newResults = filters.map(function () { return null; });
                    setLoading(newLoading);
                    setResults(newResults);
                    allResults = [];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, getAllSolicitudesServicios(token)];
                case 2:
                    result = _a.sent();
                    solicitudesServicios = result.solicitudesServicios;
                    _loop_1 = function (i) {
                        var filter = filters[i];
                        var filteredSolicitudes = solicitudesServicios.filter(function (solicitud) {
                            return selectedFields.every(function (field) {
                                var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                                // Excluir órdenes con servicio "Preventivo" si SolicitudPageType es "SolicitudesServicios-pendientes-CIG"
                                if (SolicitudPageType === 'SolicitudesServicios-pendientes-CIG' && ((_a = solicitud === null || solicitud === void 0 ? void 0 : solicitud.id_servicio) === null || _a === void 0 ? void 0 : _a._id) === '65a92d6b956f2f16bab82a9c') {
                                    return false; // Excluir esta orden
                                }
                                if (field === 'ID') {
                                    return !filter._id || solicitud._id === filter._id;
                                }
                                if (field === 'SERVICIO') {
                                    return (!filter['id_servicio._id'] ||
                                        ((_b = solicitud.id_servicio) === null || _b === void 0 ? void 0 : _b._id) === filter['id_servicio._id']);
                                }
                                if (field === 'SOLICITUD FECHA') {
                                    return !filter['creacion'] || ((_c = solicitud.creacion) === null || _c === void 0 ? void 0 : _c.includes(filter['creacion']));
                                }
                                if (field === 'EQUIPO') {
                                    var equipoFilter = ((_d = filter['id_equipo.equipo']) === null || _d === void 0 ? void 0 : _d.toLowerCase()) || '';
                                    var equipoValue = ((_h = (_g = (_f = (_e = solicitud.id_equipo) === null || _e === void 0 ? void 0 : _e.modelo_equipos) === null || _f === void 0 ? void 0 : _f.id_clase) === null || _g === void 0 ? void 0 : _g.clase) === null || _h === void 0 ? void 0 : _h.toLowerCase()) || '';
                                    return !equipoFilter || equipoValue.includes(equipoFilter);
                                }
                                if (field === 'ESTADO') {
                                    return (!filter['id_solicitud_estado.estado'] ||
                                        ((_j = solicitud.id_solicitud_estado) === null || _j === void 0 ? void 0 : _j.estado) === filter['id_solicitud_estado.estado']);
                                }
                                return true;
                            });
                        });
                        if (filteredSolicitudes.length > 0) {
                            newResults[i] = true;
                            allResults.push.apply(allResults, filteredSolicitudes);
                        }
                        else {
                            newResults[i] = false;
                        }
                    };
                    for (i = 0; i < filters.length; i++) {
                        _loop_1(i);
                    }
                    paginatedResults = allResults.slice((currentPage - 1) * limitPerPage, currentPage * limitPerPage);
                    onApplyFilters(paginatedResults, allResults.length);
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    console.error('Error al obtener las solicitudes de servicios:', error_1);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(newLoading.map(function () { return false; }));
                    setResults(newResults);
                    setShowViewButton(true);
                    setIsEditing(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleView = function () {
        onClose();
    };
    var handleEdit = function () {
        setIsEditing(true);
        setShowViewButton(false);
        setResults(filters.map(function () { return null; }));
    };
    var handleCloseAlert = function (id) {
        setAlerts(alerts.filter(function (alert) { return alert.id !== id; }));
    };
    var handleFieldSelection = function (fields) {
        setSelectedFields(fields);
        var newFilters = filters.map(function (filter) {
            var newFilter = { _id: filter._id };
            if (fields.includes('SERVICIO')) {
                newFilter['id_servicio._id'] = filter['id_servicio._id'] || '';
            }
            if (fields.includes('SOLICITUD FECHA')) {
                newFilter['creacion'] = filter['creacion'] || '';
            }
            if (fields.includes('EQUIPO')) {
                newFilter['id_equipo.equipo'] = filter['id_equipo.equipo'] || '';
            }
            if (fields.includes('ESTADO')) {
                newFilter['id_solicitud_estado.estado'] = filter['id_solicitud_estado.estado'] || '';
            }
            return newFilter;
        });
        setFilters(newFilters);
        setShowFieldSelector(false);
        // Guardar los campos seleccionados y filtros en session storage
        sessionStorage.setItem('selectedFields', JSON.stringify(fields));
        sessionStorage.setItem('userFilters', JSON.stringify(newFilters));
    };
    return (isOpen ? (_jsxs("div", { className: "AdvancedFilters-modal-overlay", children: [_jsx("div", { className: "AdvancedFilters-modal-content", children: _jsx("div", { className: "AdvancedFilters-advanced-filters", children: _jsxs("div", { className: "AdvancedFilters-div", children: [_jsxs("div", { className: "AdvancedFilters-overlap", children: [_jsx("header", { className: "AdvancedFilters-header", children: _jsx("div", { className: "AdvancedFilters-overlap-group", children: _jsx("div", { className: "AdvancedFilters-filter-title", children: "FILTRAR SOLICITUDES DE SERVICIO" }) }) }), isEditing && _jsx(AddCircleIcon, { className: "AdvancedFilters-filter-add-icon", onClick: handleAddFilter }), _jsx(FilterListIcon, { className: "AdvancedFilters-options-icon", onClick: function () { return setShowFieldSelector(true); } })] }), filters.length > 0 && (_jsx(FiltersInputsSolicitudesServicioComponent, { filters: filters, selectedFields: selectedFields, handleFilterChange: handleFilterChange, handleRemoveFilter: handleRemoveFilter, loading: loading, results: results, isEditing: isEditing, token: token, SolicitudPageType: SolicitudPageType })), isEditing ? (_jsx("button", { className: "AdvancedFilters-cancel-button", onClick: onClose, children: "Cancelar" })) : (_jsx("button", { className: "AdvancedFilters-edit-button", onClick: handleEdit, children: "Editar" })), showViewButton ? (_jsx("button", { className: "AdvancedFilters-view-button", onClick: handleView, children: "Ver" })) : (_jsx("button", { className: "AdvancedFilters-send-button", onClick: handleSearch, children: "Enviar" }))] }) }) }), _jsx(MuiAlertComponent, { alerts: alerts, onClose: handleCloseAlert }), showFieldSelector && (_jsx(FiltersCamposToSelectSolicitudesServicios, { onSubmit: handleFieldSelection, onClose: function () { return setShowFieldSelector(false); }, initialSelectedFields: selectedFields }))] })) : null);
};
export default AdvancedFiltersSolicitudesServicio;
