var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import { getAllServiciosTipos } from '../../../solicitudes/services/serviciosTiposService';
var FiltersInputsSolicitudesServicioComponent = function (_a) {
    var filters = _a.filters, selectedFields = _a.selectedFields, handleFilterChange = _a.handleFilterChange, handleRemoveFilter = _a.handleRemoveFilter, loading = _a.loading, results = _a.results, isEditing = _a.isEditing, token = _a.token, SolicitudPageType = _a.SolicitudPageType;
    var _b = useState([]), serviciosTipos = _b[0], setServiciosTipos = _b[1];
    useEffect(function () {
        var fetchServiciosTipos = function () { return __awaiter(void 0, void 0, void 0, function () {
            var servicios, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, getAllServiciosTipos(token)];
                    case 1:
                        servicios = _a.sent();
                        setServiciosTipos(servicios);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Error fetching servicios tipos:', error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        fetchServiciosTipos();
    }, [token]);
    var handleSelectChange = function (index, e) {
        var selectedValue = e.target.value;
        handleFilterChange(index, 'id_servicio._id', selectedValue);
    };
    var handleEstadoChange = function (index, e) {
        var selectedValue = e.target.value;
        handleFilterChange(index, 'id_solicitud_estado.estado', selectedValue);
    };
    var handleDateChange = function (index, e) {
        var input = e.target.value.replace(/[^0-9\-: ]/g, ''); // Permitir solo números, guiones, espacios y dos puntos
        handleFilterChange(index, 'creacion', input);
    };
    return (_jsx("ul", { className: "AdvancedFilters-filter-ul", children: filters.map(function (filter, index) {
            var _a;
            return (_jsxs("li", { className: "AdvancedFilters-filter-li", children: [selectedFields.includes('ID') && (_jsxs("div", { className: "AdvancedFilters-id-parameter", children: [_jsx("input", { type: "text", className: "AdvancedFilters-id-input", value: filter._id || '', pattern: "[0-9a-fA-F]{0,24}", maxLength: 24, onChange: function (e) { return handleFilterChange(index, '_id', e.target.value); }, disabled: !isEditing }), _jsx("div", { className: "AdvancedFilters-id-title", children: "ID" })] })), selectedFields.includes('SERVICIO') && (_jsxs("div", { className: "AdvancedFilters-id-parameter", children: [_jsxs("select", { className: "AdvancedFilters-id-input", value: SolicitudPageType === 'SolicitudesServicios-pendientes-preventivo'
                                    ? ((_a = serviciosTipos.find(function (servicio) { return servicio.servicio === 'Preventivo'; })) === null || _a === void 0 ? void 0 : _a._id) || ''
                                    : filter['id_servicio._id'] || '', onChange: function (e) { return handleSelectChange(index, e); }, disabled: !isEditing || SolicitudPageType === 'SolicitudesServicios-pendientes-preventivo', children: [_jsx("option", { value: "", disabled: true, children: "Seleccione un servicio" }), serviciosTipos.map(function (servicio) { return (_jsx("option", { value: servicio._id, disabled: SolicitudPageType === 'SolicitudesServicios-pendientes-CIG' && servicio.servicio === 'Preventivo', children: servicio.servicio }, servicio._id)); })] }), _jsx("div", { className: "AdvancedFilters-id-title", children: "SERVICIO" })] })), selectedFields.includes('SOLICITUD FECHA') && (_jsxs("div", { className: "AdvancedFilters-id-parameter", children: [_jsx("input", { type: "text", className: "AdvancedFilters-id-input", value: filter['creacion'] || '', placeholder: "YYYY-MM-DD HH:MM", onChange: function (e) { return handleDateChange(index, e); }, disabled: !isEditing }), _jsx("div", { className: "AdvancedFilters-id-title", children: "SOLICITUD FECHA" })] })), selectedFields.includes('EQUIPO') && (_jsxs("div", { className: "AdvancedFilters-id-parameter", children: [_jsx("input", { type: "text", className: "AdvancedFilters-id-input", value: filter['id_equipo.equipo'] || '', onChange: function (e) { return handleFilterChange(index, 'id_equipo.equipo', e.target.value); }, disabled: !isEditing }), _jsx("div", { className: "AdvancedFilters-id-title", children: "EQUIPO" })] })), selectedFields.includes('ESTADO') && (_jsxs("div", { className: "AdvancedFilters-id-parameter", children: [_jsxs("select", { className: "AdvancedFilters-id-input", value: SolicitudPageType === 'SolicitudesServicios-pendientes-preventivo' ||
                                    SolicitudPageType === 'SolicitudesServicios-pendientes-CIG'
                                    ? 'Pendiente'
                                    :
                                        SolicitudPageType === 'SolicitudesServicios-aprobadas'
                                            ? 'Aprobada' :
                                            SolicitudPageType === 'SolicitudesServicios-rechazadas'
                                                ? 'Rechazada' :
                                                filter['id_solicitud_estado.estado'] || '', onChange: function (e) { return handleEstadoChange(index, e); }, disabled: !isEditing ||
                                    SolicitudPageType === 'SolicitudesServicios-pendientes-preventivo' ||
                                    SolicitudPageType === 'SolicitudesServicios-pendientes-CIG' ||
                                    SolicitudPageType === 'SolicitudesServicios-aprobadas' ||
                                    SolicitudPageType === 'SolicitudesServicios-rechazadas', children: [_jsx("option", { value: "", disabled: true, children: "Seleccione un estado" }), _jsx("option", { value: "Pendiente", children: "Pendiente" }), _jsx("option", { value: "Aprobada", children: "Aprobada" }), _jsx("option", { value: "Rechazada", children: "Rechazada" })] }), _jsx("div", { className: "AdvancedFilters-id-title", children: "ESTADO" })] })), loading[index] ? (_jsx(CircularProgress, { className: "AdvancedFilters-loading-icon" })) : results[index] === true ? (_jsx(CheckCircleIcon, { className: "AdvancedFilters-check-icon" })) : results[index] === false ? (_jsx(CancelIcon, { className: "AdvancedFilters-error-icon" })) : (isEditing && _jsx(CancelIcon, { className: "AdvancedFilters-delete-icon", onClick: function () { return handleRemoveFilter(index); } })), _jsx("div", { className: "AdvancedFilters-separator" })] }, index));
        }) }));
};
export default FiltersInputsSolicitudesServicioComponent;
