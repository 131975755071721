// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActividadesVisitas-div{
    height: auto;
    width: 100%;
    position: absolute;
    top: 97%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ActividadesVisitas-set-loading{
    height: auto;
    font-size: 16px;
    width: 100%;
    position: absolute;
    top: 97%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #00ddfa;

}

.ActividadesVisitas-container{
    height: auto;
    width: 100%;
    position: absolute;
    top: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}



.ActividadesVisitas-title{
    position: relative;
    top: 0;
    left: 0;
    height: 25px;
    width: 300px;
    font-size: 16px;
    text-align: center;
    border-radius: 10px;
    color: #00ddfa;
    background-color: #ffffff;

}
`, "",{"version":3,"sources":["webpack://./src/modules/ordenes/components/ordenes_servicios/styles/ActividadesVisitas.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,cAAc;;AAElB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;;;AAIA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;IACd,yBAAyB;;AAE7B","sourcesContent":[".ActividadesVisitas-div{\n    height: auto;\n    width: 100%;\n    position: absolute;\n    top: 97%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.ActividadesVisitas-set-loading{\n    height: auto;\n    font-size: 16px;\n    width: 100%;\n    position: absolute;\n    top: 97%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    color: #00ddfa;\n\n}\n\n.ActividadesVisitas-container{\n    height: auto;\n    width: 100%;\n    position: absolute;\n    top: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n\n\n.ActividadesVisitas-title{\n    position: relative;\n    top: 0;\n    left: 0;\n    height: 25px;\n    width: 300px;\n    font-size: 16px;\n    text-align: center;\n    border-radius: 10px;\n    color: #00ddfa;\n    background-color: #ffffff;\n\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
