var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material';
import '../../../../../components/filters/styles/FiltersCamposToSelect.css';
var FiltersCamposToSelectSedes = function (_a) {
    var onSubmit = _a.onSubmit, onClose = _a.onClose, initialSelectedFields = _a.initialSelectedFields;
    var fields = useState([
        'ID',
        'SEDE', // Añadimos el campo 'SEDE' para permitir la selección de filtro por nombre de sede
    ])[0];
    var _b = useState([]), selectedFields = _b[0], setSelectedFields = _b[1];
    useEffect(function () {
        setSelectedFields(initialSelectedFields);
    }, [initialSelectedFields]);
    var handleFieldChange = function (field) {
        var updatedFields = selectedFields.includes(field)
            ? selectedFields.filter(function (f) { return f !== field; })
            : __spreadArray(__spreadArray([], selectedFields, true), [field], false);
        setSelectedFields(updatedFields);
        // Guardar los campos actualizados en session storage
        sessionStorage.setItem('selectedFieldsSedes', JSON.stringify(updatedFields));
    };
    var handleSubmit = function () {
        onSubmit(selectedFields);
        onClose();
    };
    return (_jsx("div", { className: "FiltersCamposToSelect-box", children: _jsx("div", { className: "FiltersCamposToSelect-select-campos", children: _jsxs("div", { className: "FiltersCamposToSelect-overlap", children: [_jsx("div", { className: "FiltersCamposToSelect-container" }), _jsx("div", { className: "FiltersCamposToSelect-header-section", children: _jsx("div", { className: "FiltersCamposToSelect-overlap-group", children: _jsx("div", { className: "FiltersCamposToSelect-header-title", children: "SELECCIONAR CAMPOS" }) }) }), _jsx("div", { className: "FiltersCamposToSelect-campo-t", children: "CAMPO" }), _jsx("div", { className: "FiltersCamposToSelect-visible-t", children: "VISIBLE" }), _jsx("ul", { className: "FiltersCamposToSelect-campo-section", children: fields.map(function (field) { return (_jsxs("li", { className: "FiltersCamposToSelect-div", children: [_jsx("div", { className: "FiltersCamposToSelect-campo-value", children: field }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { className: "FiltersCamposToSelect-check-box", checked: selectedFields.includes(field), onChange: function () { return handleFieldChange(field); }, style: { color: '#00ddfa' } }), label: "" })] }, field)); }) }), _jsx("button", { className: "FiltersCamposToSelect-cancel-button", onClick: onClose, children: "Cancelar" }), _jsx("button", { className: "FiltersCamposToSelect-send-button", onClick: handleSubmit, children: "Enviar" })] }) }) }));
};
export default FiltersCamposToSelectSedes;
