var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { getPresignedUrl, updateVisita } from '../../services/visitasService';
import { useSessionStorage } from '../../../ordenes/hooks/useSessionStorage';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import './styles/TecnicoEnSede.css';
import { CircularProgress } from '@mui/material';
var RegisterTecnicoEnSede = function (_a) {
    var idVisita = _a.idVisita, onActividadRegistrada = _a.onActividadRegistrada;
    var token = useSessionStorage('sessionJWTToken');
    var _b = useState(''), idProtocolo = _b[0], setIdProtocolo = _b[1];
    var _c = useState(''), observacion = _c[0], setObservacion = _c[1];
    var _d = useState(''), idImage = _d[0], setIdImage = _d[1];
    var _e = useState(false), isLoading = _e[0], setIsLoading = _e[1];
    var _f = useState(null), capturedImage = _f[0], setCapturedImage = _f[1];
    var handleCapture = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var file, sanitizedFileName, imageUrl, response, presignedUrl, uploadedImageUrl, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    file = event.target.files ? event.target.files[0] : null;
                    if (!(file && token)) return [3 /*break*/, 5];
                    sanitizedFileName = file.name
                        .replace(/\s+/g, '_') // Reemplazar espacios por guiones bajos
                        .replace(/[^\w.-]/g, '');
                    imageUrl = URL.createObjectURL(file);
                    setCapturedImage(imageUrl);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    setIsLoading(true);
                    return [4 /*yield*/, getPresignedUrl(token, sanitizedFileName)];
                case 2:
                    response = _a.sent();
                    presignedUrl = response.presignedUrl;
                    return [4 /*yield*/, fetch(presignedUrl, {
                            method: 'PUT',
                            headers: {
                                'Content-Type': file.type, // Ajuste según el tipo de archivo
                            },
                            body: file,
                        })];
                case 3:
                    _a.sent();
                    uploadedImageUrl = presignedUrl.split('?')[0];
                    setIdImage(uploadedImageUrl);
                    setIsLoading(false);
                    return [3 /*break*/, 5];
                case 4:
                    error_1 = _a.sent();
                    console.error('Error al subir la imagen:', error_1);
                    setIsLoading(false);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleSelectChange = function (e) {
        setIdProtocolo(e.target.value);
        setIsLoading(true); // Inicia la carga
        // Simula un proceso de carga antes de mostrar el contenido
        setTimeout(function () {
            setIsLoading(false); // Finaliza la carga
        }, 1000); // Ajusta el tiempo según sea necesario
    };
    var validateForm = function () {
        var missingFields = [];
        if (!idProtocolo)
            missingFields.push("Actividad");
        if (!observacion)
            missingFields.push("Observación");
        if (!idImage)
            missingFields.push("Imagen Capturada");
        if (missingFields.length > 0) {
            alert("Por favor, complete los siguientes campos: ".concat(missingFields.join(", ")));
            return false;
        }
        return true;
    };
    var now = new Date();
    var formattedDateCreated = "".concat(now.getFullYear(), "-").concat((now.getMonth() + 1).toString().padStart(2, '0'), "-").concat(now.getDate().toString().padStart(2, '0'), " ").concat(now.getHours().toString().padStart(2, '0'), ":").concat(now.getMinutes().toString().padStart(2, '0'), ":").concat(now.getSeconds().toString().padStart(2, '0'));
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var nuevaActividad, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    if (!validateForm())
                        return [2 /*return*/];
                    nuevaActividad = {
                        actividades: [{
                                id_protocolo: idProtocolo,
                                id_image: idImage,
                                observacion: observacion.trim(),
                                date_created: formattedDateCreated,
                            }]
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, 6, 7]);
                    if (!(token && idVisita)) return [3 /*break*/, 3];
                    return [4 /*yield*/, updateVisita(token, idVisita, nuevaActividad)];
                case 2:
                    _a.sent();
                    alert('Actividad agregada a la visita con éxito.');
                    onActividadRegistrada();
                    return [3 /*break*/, 4];
                case 3:
                    alert('No se encontró token de sesión.');
                    _a.label = 4;
                case 4: return [3 /*break*/, 7];
                case 5:
                    error_2 = _a.sent();
                    console.error('Error al actualizar la visita:', error_2);
                    alert('Error al actualizar la visita.');
                    return [3 /*break*/, 7];
                case 6:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx("div", { className: "TecnicoEnSede-box", children: _jsxs("form", { onSubmit: handleSubmit, children: [_jsx("div", { className: "TecnicoEnSede-visita-actividad", children: _jsx("div", { className: "TecnicoEnSede-actividad-group", children: _jsxs("div", { className: "TecnicoEnSede-overlap-group", children: [_jsx("div", { className: "TecnicoEnSede-actividad-title", children: "ACTIVIDAD A EJECUTAR" }), _jsxs("select", { className: "TecnicoEnSede-actividad-select", value: idProtocolo, onChange: handleSelectChange, children: [_jsx("option", { value: "", disabled: true, selected: true, children: "Seleccione una actividad" }), _jsx("option", { value: "65a93dd789a02ef211e75ecd", children: "T\u00E9cnico en sede" })] })] }) }) }), isLoading ? (_jsxs("div", { style: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }, children: [_jsx(CircularProgress, { className: 'TecnicoEnSede-loading-icon' }), " "] })) : (idProtocolo === '65a93dd789a02ef211e75ecd' && (_jsx("div", { className: "TecnicoEnSede-tecnico-en-sede", children: _jsxs("div", { className: "TecnicoEnSede-en-sede-group", children: [_jsx("div", { className: "TecnicoEnSede-overlap-group2", children: _jsx("div", { className: "TecnicoEnSede-tecsede-title", children: "T\u00C9CNICO EN SEDE" }) }), _jsx("label", { htmlFor: "observacion", className: "TecnicoEnSede-observacion-t", children: "Observaci\u00F3n: *" }), _jsx("textarea", { id: "observacion", className: "TecnicoEnSede-observacion-text", value: observacion, onChange: function (e) { return setObservacion(e.target.value); }, placeholder: "Escribe tu observaci\u00F3n aqu\u00ED..." }), _jsx("label", { htmlFor: "capture", className: "TecnicoEnSede-image-input-label", children: _jsx(AddAPhotoIcon, { className: "TecnicoEnSede-image-input" }) }), _jsx("input", { id: "capture", type: "file", accept: "image/*", capture: "environment" // Usa 'environment' para preferir la cámara trasera
                                , onChange: handleCapture, style: { display: 'none' } }), capturedImage &&
                                _jsx("img", { src: capturedImage, alt: "Captura", style: { width: '100px', height: '100px', top: '227px', left: '174px', position: 'absolute' } }), _jsx("button", { type: 'submit', className: "TecnicoEnSede-crear-actividad", children: "CREAR ACTIVIDAD" })] }) })))] }) }));
};
export default RegisterTecnicoEnSede;
